/**
 * @generated SignedSource<<085b1c00bdecc1c6603a17459a36ce80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomAttributeSelectTargetingCommonQuery$variables = {
  after?: string | null;
  companyId: string;
  hasSelectedInternalIdValue: boolean;
  hasSelectedNodeIdValue: boolean;
  pageSize: number;
  selectedInternalIdValue: string;
  selectedNodeValue: string;
};
export type CustomAttributeSelectTargetingCommonQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CustomAttributeSelectTargetingCommon_fragment" | "useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment">;
};
export type CustomAttributeSelectTargetingCommonQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly id: string;
    readonly userPropertyDefinitionsV2: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly __typename: "CustomPropertyDefinition";
          readonly __isNode: "CustomPropertyDefinition";
          readonly customPropertyId: {
            readonly id: string;
          };
          readonly id: string;
          readonly name: string;
        } | {
          readonly __typename: string;
          readonly __isNode: string;
          readonly id: string;
        };
      }>;
      readonly pageInfo: {
        readonly endCursor: string;
        readonly hasNextPage: boolean;
      };
    } | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
  readonly externalIdResponse: {
    readonly __typename: "CustomPropertyDefinition";
    readonly customPropertyId: {
      readonly id: string;
    };
    readonly id: string;
    readonly name: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
  readonly internalIdResponse: {
    readonly __typename: "Company";
    readonly customPropertyDefinitionByInternalId: {
      readonly customPropertyId: {
        readonly id: string;
      };
      readonly id: string;
      readonly name: string;
    } | null;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type CustomAttributeSelectTargetingCommonQuery = {
  rawResponse: CustomAttributeSelectTargetingCommonQuery$rawResponse;
  response: CustomAttributeSelectTargetingCommonQuery$data;
  variables: CustomAttributeSelectTargetingCommonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSelectedInternalIdValue"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSelectedNodeIdValue"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedInternalIdValue"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedNodeValue"
},
v7 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v8 = {
  "kind": "Variable",
  "name": "companyId",
  "variableName": "companyId"
},
v9 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  (v7/*: any*/),
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "includeDeleted": false,
      "source": "USER_PROPERTY_SOURCE_CUSTOM"
    }
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = [
  (v11/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomPropertyID",
  "kind": "LinkedField",
  "name": "customPropertyId",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = [
  (v11/*: any*/),
  (v13/*: any*/),
  (v15/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomAttributeSelectTargetingCommonQuery",
    "selections": [
      {
        "args": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "Variable",
            "name": "pageSize",
            "variableName": "pageSize"
          }
        ],
        "kind": "FragmentSpread",
        "name": "CustomAttributeSelectTargetingCommon_fragment"
      },
      {
        "args": [
          (v8/*: any*/),
          {
            "kind": "Variable",
            "name": "hasSelectedInternalIdValue",
            "variableName": "hasSelectedInternalIdValue"
          },
          {
            "kind": "Variable",
            "name": "hasSelectedNodeIdValue",
            "variableName": "hasSelectedNodeIdValue"
          },
          {
            "kind": "Variable",
            "name": "selectedInternalIdValue",
            "variableName": "selectedInternalIdValue"
          },
          {
            "kind": "Variable",
            "name": "selectedNodeValue",
            "variableName": "selectedNodeValue"
          }
        ],
        "kind": "FragmentSpread",
        "name": "useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "CustomAttributeSelectTargetingCommonQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v9/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "UserPropertyDefinitionConnectionV2",
                "kind": "LinkedField",
                "name": "userPropertyDefinitionsV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserPropertyDefinitionEdgeV2",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v16/*: any*/),
                            "type": "CustomPropertyDefinition",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v14/*: any*/),
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": [
                  "filter"
                ],
                "handle": "connection",
                "key": "CustomAttributeSelectTargetingCommon_userPropertyDefinitionsV2",
                "kind": "LinkedHandle",
                "name": "userPropertyDefinitionsV2"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasSelectedInternalIdValue",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "internalIdResponse",
            "args": (v9/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "internalId",
                        "variableName": "selectedInternalIdValue"
                      }
                    ],
                    "concreteType": "CustomPropertyDefinition",
                    "kind": "LinkedField",
                    "name": "customPropertyDefinitionByInternalId",
                    "plural": false,
                    "selections": (v16/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "Company",
                "abstractKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasSelectedNodeIdValue",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "externalIdResponse",
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "selectedNodeValue"
              }
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v13/*: any*/),
                  (v15/*: any*/)
                ],
                "type": "CustomPropertyDefinition",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "58e6a99e686512ae8f08330b089fead3",
    "id": null,
    "metadata": {},
    "name": "CustomAttributeSelectTargetingCommonQuery",
    "operationKind": "query",
    "text": "query CustomAttributeSelectTargetingCommonQuery(\n  $after: String\n  $pageSize: Int!\n  $companyId: ID!\n  $hasSelectedInternalIdValue: Boolean!\n  $selectedInternalIdValue: String!\n  $hasSelectedNodeIdValue: Boolean!\n  $selectedNodeValue: ID!\n) {\n  ...CustomAttributeSelectTargetingCommon_fragment_1BNOK0\n  ...useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment_OX3fT\n}\n\nfragment CustomAttributeSelectTargetingCommon_fragment_1BNOK0 on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      id\n      userPropertyDefinitionsV2(first: $pageSize, after: $after, filter: {includeDeleted: false, source: USER_PROPERTY_SOURCE_CUSTOM}) {\n        edges {\n          node {\n            __typename\n            ... on CustomPropertyDefinition {\n              id\n              name\n              customPropertyId {\n                id\n              }\n            }\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment useGetSelectedItem_CustomAttributeSelect_TargetingCommon_fragment_OX3fT on Query {\n  internalIdResponse: node(id: $companyId) @include(if: $hasSelectedInternalIdValue) {\n    __typename\n    ... on Company {\n      customPropertyDefinitionByInternalId(internalId: $selectedInternalIdValue) {\n        id\n        name\n        customPropertyId {\n          id\n        }\n      }\n    }\n    id\n  }\n  externalIdResponse: node(id: $selectedNodeValue) @include(if: $hasSelectedNodeIdValue) {\n    __typename\n    ... on CustomPropertyDefinition {\n      id\n      name\n      customPropertyId {\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a19fddb67010f8da007d0fe78d8cb8e";

export default node;
