/**
 * @generated SignedSource<<062ee2f58da4ab5dd35fb4bf638d130f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomAttributeSelectTargetingCommon_Query_refetchable$variables = {
  after?: string | null;
  companyId: string;
  pageSize: number;
};
export type CustomAttributeSelectTargetingCommon_Query_refetchable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CustomAttributeSelectTargetingCommon_fragment">;
};
export type CustomAttributeSelectTargetingCommon_Query_refetchable = {
  response: CustomAttributeSelectTargetingCommon_Query_refetchable$data;
  variables: CustomAttributeSelectTargetingCommon_Query_refetchable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "includeDeleted": false,
      "source": "USER_PROPERTY_SOURCE_CUSTOM"
    }
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  }
],
v5 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomAttributeSelectTargetingCommon_Query_refetchable",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "companyId",
            "variableName": "companyId"
          },
          {
            "kind": "Variable",
            "name": "pageSize",
            "variableName": "pageSize"
          }
        ],
        "kind": "FragmentSpread",
        "name": "CustomAttributeSelectTargetingCommon_fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomAttributeSelectTargetingCommon_Query_refetchable",
    "selections": [
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "UserPropertyDefinitionConnectionV2",
                "kind": "LinkedField",
                "name": "userPropertyDefinitionsV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserPropertyDefinitionEdgeV2",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CustomPropertyID",
                                "kind": "LinkedField",
                                "name": "customPropertyId",
                                "plural": false,
                                "selections": (v5/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "CustomPropertyDefinition",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v5/*: any*/),
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "filter"
                ],
                "handle": "connection",
                "key": "CustomAttributeSelectTargetingCommon_userPropertyDefinitionsV2",
                "kind": "LinkedHandle",
                "name": "userPropertyDefinitionsV2"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b0b7ab176847d12f733e6657c50bb81",
    "id": null,
    "metadata": {},
    "name": "CustomAttributeSelectTargetingCommon_Query_refetchable",
    "operationKind": "query",
    "text": "query CustomAttributeSelectTargetingCommon_Query_refetchable(\n  $after: String\n  $companyId: ID!\n  $pageSize: Int!\n) {\n  ...CustomAttributeSelectTargetingCommon_fragment_1BNOK0\n}\n\nfragment CustomAttributeSelectTargetingCommon_fragment_1BNOK0 on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      id\n      userPropertyDefinitionsV2(first: $pageSize, after: $after, filter: {includeDeleted: false, source: USER_PROPERTY_SOURCE_CUSTOM}) {\n        edges {\n          node {\n            __typename\n            ... on CustomPropertyDefinition {\n              id\n              name\n              customPropertyId {\n                id\n              }\n            }\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4f6ceec995bff85395bdd068a8fb7ca";

export default node;
