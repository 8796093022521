import React, { useEffect, useRef, useState } from 'react';

import { useCurrentUser, useLocaleConfig } from '@attentive/acore-utils';
import { DateFilterPeriod } from '@attentive/data/types';
import { DateFormat, formatDateWithLocalTime, Locale } from '@attentive/locale-utils';
import {
  DateRangeChangeType,
  DateRangePicker as PicnicDateRangePicker,
  Box,
  Text,
  PicnicCss,
} from '@attentive/picnic';
import {
  QuickDateRangePicker,
  getDateRangeOptions,
  getDateRangeTitle,
  DateRangeSelectChange,
} from '@attentive/reporting-platform';

export const formatDateRange = (start: string, end: string, locale: Locale): string => {
  return `${formatDateWithLocalTime(
    start,
    DateFormat.SHORT_MONTH,
    locale
  )} - ${formatDateWithLocalTime(end, DateFormat.SHORT_MONTH, locale)}`;
};

type FilterPeriodRangePickerProps = React.ComponentProps<typeof QuickDateRangePicker> & {
  startDate: string;
  endDate: string;
  dateFilterPeriod: DateFilterPeriod | null;
  onApplyChanges: (
    startDate: string,
    endDate: string,
    filterPeriod: DateFilterPeriod | null
  ) => void;
  side?: 'top' | 'bottom' | 'left' | 'right';
  css?: PicnicCss;
};

export const FilterPeriodDateRangePicker: React.FC<FilterPeriodRangePickerProps> = ({
  startDate,
  endDate,
  dateFilterPeriod,
  onApplyChanges,
  maxExpectedYear = new Date().getFullYear(),
  minExpectedYear = 2017,
  isDayBlocked,
  minimumNights,
  side = 'bottom',
  css,
}) => {
  const { timezone } = useCurrentUser().company;

  const [isOpen, setIsOpen] = useState(false);
  // keep track of this state locally because we only call onChange() if user clicks "Apply"
  const [localStartDate, setLocalStartDate] = useState<string | null>(startDate);
  const [localEndDate, setLocalEndDate] = useState<string | null>(endDate);
  const [localDateFilterPeriod, setLocalDateFilterPeriod] = useState<DateFilterPeriod | null>(
    dateFilterPeriod
  );
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { locale } = useLocaleConfig();

  const formattedDateRange = formatDateRange(startDate, endDate, locale);

  useEffect(() => {
    // when modal opens or closes, reset parameters based on props
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
    setLocalDateFilterPeriod(dateFilterPeriod);
  }, [isOpen, startDate, endDate, dateFilterPeriod]);

  useEffect(() => {
    setIsOpen(false);
  }, [startDate, endDate, dateFilterPeriod]);

  const handleLocalDateChange: DateRangeChangeType = (changedDates) => {
    setLocalStartDate(changedDates.startDate);
    setLocalEndDate(changedDates.endDate);
    setLocalDateFilterPeriod(null);
  };

  const handleLocalDatePeriodChange: DateRangeSelectChange = (dateRange) => {
    setLocalStartDate(dateRange.startDate);
    setLocalEndDate(dateRange.endDate);
    setLocalDateFilterPeriod(dateRange.dateFilterPeriod);
  };

  const handleApplyDataChanges = () => {
    if (localStartDate && localEndDate) {
      onApplyChanges(localStartDate, localEndDate, localDateFilterPeriod);
    }
    setIsOpen(false);
  };

  const handleCancelDataChanges = () => {
    setIsOpen(false);
  };

  const dateRangeOptions = getDateRangeOptions({
    companyTimezone: timezone,
    includeCustom: true,
  });
  const dateRangeLabel = getDateRangeTitle(dateFilterPeriod, dateRangeOptions);

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        ...css,
      }}
    >
      <Box>
        <QuickDateRangePicker.Root open={isOpen} onOpenChange={setIsOpen}>
          <QuickDateRangePicker.Trigger>
            <PicnicDateRangePicker.Input
              size="small"
              state="normal"
              value={dateRangeLabel}
              description="Open date range picker"
              inputGroupRef={inputRef}
            />
          </QuickDateRangePicker.Trigger>
          <QuickDateRangePicker.Popover side={side}>
            <QuickDateRangePicker.Control
              startDate={localStartDate}
              endDate={localEndDate}
              onChange={handleLocalDateChange}
              onChangeDatePeriod={handleLocalDatePeriodChange}
              maxExpectedYear={maxExpectedYear}
              minExpectedYear={minExpectedYear}
              isDayBlocked={isDayBlocked}
              minimumNights={minimumNights}
              dateFilterPeriod={localDateFilterPeriod}
              dateRangeOptions={dateRangeOptions}
              companyTimezone={timezone}
              shouldUseInternalCalendar={false}
            />
            <QuickDateRangePicker.Buttons
              handleCancelDateChange={handleCancelDataChanges}
              handleApplyDateChange={handleApplyDataChanges}
            />
          </QuickDateRangePicker.Popover>
        </QuickDateRangePicker.Root>
      </Box>

      <Text
        data-testid="date-range-text"
        variant="caption"
        css={{
          color: '$textSubdued',
          mt: '$space1',
        }}
      >
        {formattedDateRange}
      </Text>
    </Box>
  );
};
